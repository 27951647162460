

















































import { Vue, Component } from "vue-property-decorator";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";
import TokenService from "@/services/TokenService";
import WebsiteApiService from "@/services/WebsiteApiService";
import { getFirstQueryValue } from "@/utils/queryParams";
import { ReferenceItem } from "@/services/types";
import ProjectsService from "@/services/ProjectsService";

@Component({
  components: {
    AppFormWrapper,
  },
})
export default class ExposeRejectionView extends Vue {
  gotResponse = false;
  error: string | null = null;
  rejectionReason: ReferenceItem[] = [];
  dismissedStatusId = 0;
  additionalComment = "";
  projectId = 0;
  buyerId = 0;

  isRejected = false;

  get token(): string {
    return getFirstQueryValue(this.$route.query.token) ?? "";
  }

  async submitForm() {
    const statusId =
      this.dismissedStatusId ||
      this.rejectionReason.find((item) => item.name === "Andere Gründe")?.id;
    if (!statusId) return console.log("STATUS ID ERROR");
    this.gotResponse = false;
    try {
      await ProjectsService.rejectExpose(
        this.token,
        this.projectId,
        statusId,
        this.additionalComment
      );
      this.isRejected = true;
    } catch (error) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.gotResponse = true;
    }
  }

  async created() {
    const tokenMetadata = await TokenService.isValid(this.token);
    if (tokenMetadata.expired) {
      this.error = "token";
    } else if (tokenMetadata.meta.buyerId && tokenMetadata.meta.projectId) {
      this.rejectionReason = (
        await WebsiteApiService.getTeaserDismissedStatuses()
      ).filter((item) => item.id !== 6 && item.id !== 9);
      this.projectId = tokenMetadata.meta.projectId;
      this.buyerId = tokenMetadata.meta.buyerId;
    }
    this.gotResponse = true;
  }
}
